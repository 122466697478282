import { createSelector } from 'reselect';
import getContentStateComments from '@/editor/utils/getContentStateComments';

const getEditorState = (state) => state.editor.editorState;
const getContentState = (state) => state?.editor?.editorState.getCurrentContent();
const getTitleContentState = (state) => state?.editor?.titleEditorState.getCurrentContent();

export const getContentPlainText = createSelector(getContentState, (content) =>
  content.getPlainText(),
);

export const getTitlePlainText = createSelector(getTitleContentState, (content) =>
  content.getPlainText(),
);

export const getEditorComments = createSelector(getContentState, (content) =>
  getContentStateComments(content),
);

export const getTitleEditorComments = createSelector(getTitleContentState, (titleContent) =>
  getContentStateComments(titleContent),
);

export const getSelectedEditorBlock = createSelector(getEditorState, (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selectionState.getStartKey());
  return block;
});
