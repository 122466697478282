export default function getContentStateComments(contentState) {
  let styles = [];

  if (contentState && typeof contentState.getBlocksAsArray === 'function') {
    contentState.getBlocksAsArray().forEach((block) => {
      const currentBlockKey = block.getKey();

      let currentInlineStyles = null;
      block.findStyleRanges(
        (character) => {
          currentInlineStyles = null;
          if (character.getStyle() !== null) {
            let inlineStyles = character.getStyle();

            inlineStyles = inlineStyles.filter(
              (value) => value.startsWith('COMMENT-') && !value.endsWith('RESOLVED'),
            );

            if (inlineStyles.size) {
              currentInlineStyles = inlineStyles;
              inlineStyles.forEach((inlineStyle) => {
                if (styles.find((style) => style.key === inlineStyle)) {
                  styles = styles.map((style) => {
                    if (style.key === inlineStyle) {
                      const ranges = style.ranges.slice();
                      ranges.push({ blockKey: currentBlockKey });

                      return { ...style, ranges };
                    }
                    return style;
                  });
                } else {
                  styles.push({
                    key: inlineStyle,
                    ranges: [{ blockKey: currentBlockKey }],
                  });
                }
              });
              return true;
            }
          }
          return false;
        },
        (start, end) => {
          styles = styles.map((style) => {
            if (currentInlineStyles.find((inlineStyle) => inlineStyle === style.key)) {
              let ranges = style.ranges.slice();
              ranges = ranges.map((range) => {
                if (range.blockKey === currentBlockKey && range.start === undefined) {
                  return { ...range, start, end };
                }
                return range;
              });
              return {
                ...style,
                ranges,
                editorText: getTextFromRange(contentState, ranges),
              };
            }

            return style;
          });
        },
      );
    });
  }

  return styles;
}

function getTextFromRange(contentState, ranges) {
  const blocks = getBlocksGroupedByKey(ranges);

  return Object.entries(blocks).map(([key, value]) =>
    getTextFromBlock(contentState, key, value.start, value.end),
  );
}

function getBlocksGroupedByKey(ranges) {
  const blocks = {};

  ranges.forEach(({ blockKey, start, end }) => {
    if (!(blockKey in blocks)) {
      blocks[blockKey] = {
        start,
        end,
      };
    } else {
      blocks[blockKey].start = Math.min(blocks[blockKey].start, start);
      blocks[blockKey].end = Math.max(blocks[blockKey].end, end);
    }
  });

  return blocks;
}

function getTextFromBlock(contentState, blockKey, start, end) {
  const blocks = contentState.getBlocksAsArray();
  const block = blocks.find(({ key }) => key === blockKey);

  if (!block) {
    return null;
  }

  return block.text.substring(start, end);
}
